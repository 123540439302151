<script setup lang="ts">
import { changeLocale } from '@formkit/vue'

const { data } = await useGlobalConfig()

useHead({
  titleTemplate: '%s',
})

useDirectusSEO(data.value?.seo as any)

// set formkit language
const { $i18n } = useNuxtApp()

watch($i18n.locale, () => {
  changeLocale($i18n.locale.value)
}, { immediate: true })
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#6DAADD" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ClientOnly>
      <Toaster
        theme="dark"
        :toast-options="{
          unstyled: true,
          classes: {
            toast: 'text-1.4rem bg-starline-secondary text-neutral-white shadow-starline border border-starline-primary p-1.2rem flex gap-.8rem items-center',
            title: 'font-bold',
            description: 'font-normal',
          },
        }" position="top-right"
      />
    </ClientOnly>
  </div>
</template>
