export function useGlobalConfig() {
  const { $directus, $readSingleton } = useNuxtApp()
  return useAsyncData<GlobalSettings>('global_settings', async () => {
    return await $directus.request($readSingleton('global_settings', {
      fields: ['*', {
        seo: ['*', {
          translations: ['*'],
        }],
        top_nav_left: ['*', {
          nav_link_id: ['*', {
            translations: ['*'],
          }],
        }],
        top_nav_right: ['*', {
          nav_link_id: ['*', {
            translations: ['*'],
          }],
        }],
        main_nav_left: [
          'collection',
          'id',
          {
            item: {
              nav_link: ['*', {
                translations: ['*'],
              }],
              main_nav_group: ['*', {
                translations: ['*'],
                items: ['*', {
                  main_nav_group_item_id: ['*', {
                    translations: ['*'],
                  }],
                }],
              }],
            },
          },
        ],
        main_nav_right: [
          'collection',
          'id',
          {
            item: {
              nav_link: ['*', {
                translations: ['*'],
              }],
              main_nav_group: ['*', {
                items: ['*', {
                  main_nav_group_item_id: ['*', {
                    translations: ['*'],
                  }],
                }],
              }],
            },
          },
        ],
        footer_links: ['*', {
          footer_link_group_id: ['*', {
            translations: ['*'],
            links: ['*', {
              footer_link_id: ['*', {
                translations: ['*'],
              }],
            }],
          }],
        }],
        mobile_nav: [
          'collection',
          'id',
          {
            item: {
              nav_link: ['*', {
                translations: ['*'],
              }],
              mobile_nav_group: ['*', {
                translations: ['*'],
                items: ['*', {
                  nav_link_id: ['*', {
                    translations: ['*'],
                  }],
                }],
              }],
            },
          },
        ],
      }],
    })) as GlobalSettings
  })
}

// Auto generated from Nuxt devtools
export interface GlobalSettings {
  id: number
  top_nav_left: FooterLink[]
  footer_links: FooterLink[]
  top_nav_right: FooterLink[]
  main_nav_left: MainNav[]
  main_nav_right: MainNav[]
  mobile_nav: MobileNav[]
  seo: SEO
}

interface FooterLink {
  id: number
  global_settings_id: number
  sort: number | null
  footer_link_group_id?: FooterLinkGroupID
  nav_link_id?: NavLinkID
}

interface FooterLinkGroupID {
  id: string
  links: LinkElement[]
  translations: FooterLinkGroupIDTranslation[]
}

interface LinkElement {
  id: number
  footer_link_group_id: string
  sort: number
  footer_link_id: FooterLinkID
}

interface FooterLinkID {
  id: number
  link: string
  translations: LinkTranslation[]
}

interface LinkTranslation {
  id: number
  footer_link_id?: number
  languages_code: string
  text: string
  nav_link_id?: string
}

interface FooterLinkGroupIDTranslation {
  id: number
  footer_link_group_id: string
  languages_code: string
  name: string
}

interface NavLinkID {
  id: string
  link: string
  translations: LinkTranslation[]
}

type MainNav = {
  collection: 'nav_link'
  id: number
  item: MainNavItem
} | {
  collection: 'main_nav_group'
  id: number
  item: {
    id: number
    items: {
      id: number
      main_nav_group_id: number
      main_nav_group_item_id: {
        id: number
        background_image: string | null
        link: string
        translations: {
          id: number
          main_nav_group_item_id: number
          languages_code: string
          title: string
          description: string
        }[]
      }

    }[]
    translations: {
      id: number
      main_nav_group_id: number
      languages_code: string
      name: string
    }[]
  }
}

interface MainNavItem {
  id: number | string
  link?: string
  translations: Array<LinkTranslation>
  items?: PurpleItem[]
}

interface PurpleItem {
  id: number
  main_nav_group_id: number
  main_nav_group_item_id: MainNavGroupItemID
}

interface MainNavGroupItemID {
  id: number
  background_image: null | string
  link: string
  translations: MainNavGroupItemIDTranslation[]
}

interface MainNavGroupItemIDTranslation {
  id: number
  main_nav_group_item_id: number
  languages_code: string
  title: string
  description: string
}

interface MobileNav {
  collection: string
  id: number
  item: MobileNavItem
}

interface MobileNavItem {
  id: number | string
  link?: string
  translations: PurpleTranslation[]
  items?: FluffyItem[]
}

interface FluffyItem {
  id: number
  mobile_nav_group_id: number
  nav_link_id: NavLinkID
}

interface PurpleTranslation {
  id: number
  nav_link_id?: string
  languages_code: string
  text?: string
  mobile_nav_group_id?: number
  name?: string
}

interface SEO {
  id: string
  sitemap_change_frequency: string
  no_index: boolean
  no_follow: boolean
  sitemap_priority: string
  i: null
  translations: SEOTranslation[]
}

interface SEOTranslation {
  id: number
  seo_id: string
  languages_id: string
  title: string
  description: string
  og_image: null
}
