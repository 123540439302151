<script setup lang="ts">
import BtnPrimary from './components/btn/BtnPrimary.vue'

const { data: pageNotFoundDAta } = await usePageNotFound()
const { data: globalData } = await useGlobalConfig()

useHead({
  titleTemplate: () => {
    return `404 - Starline Pools`
  },
})
useDirectusSEO(globalData.value?.seo as any)

const translatedTitle = computed(() => {
  return dt(pageNotFoundDAta.value?.translations)?.title ?? ''
})

const translatedDescription = computed(() => {
  return dt(pageNotFoundDAta.value?.translations)?.description ?? ''
})

const translateCta = computed(() => {
  return dt(pageNotFoundDAta.value?.translations)?.home_cta ?? ''
})
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="h-screen flex flex-col items-center justify-center gap-8 px-4">
        <h1 class="max-w-38rem text-center text-6xl font-title">
          {{ translatedTitle }}
        </h1>
        <p class="max-w-32rem whitespace-pre-wrap text-center text-lg opacity-60">
          {{ translatedDescription }}
        </p>
        <BtnPrimary
          :text="translateCta"
          link="/"
          class="text-lg"
        />
      </div>
    </NuxtLayout>
  </div>
</template>
