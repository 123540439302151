import revive_payload_client_EQ2LFEUD3g from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_stcndtzjqtng67dbiiaavebmc4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iRk5vQtCiX from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_stcndtzjqtng67dbiiaavebmc4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TrAQS82Pvl from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_stcndtzjqtng67dbiiaavebmc4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_KzLAy9rLRM from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass-embed_mnlbhtxsbqlinj76ka5sdge2yq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import directus_fdYmlw0WUJ from "/app/plugins/directus.ts";
import payload_client_3moUN1dtXk from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_stcndtzjqtng67dbiiaavebmc4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2wNRuzFp8g from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_stcndtzjqtng67dbiiaavebmc4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YlxbURKpEw from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_stcndtzjqtng67dbiiaavebmc4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ViK40YZjpZ from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_stcndtzjqtng67dbiiaavebmc4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VQIxS4wqOh from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_lc6gMMdujb from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_stcndtzjqtng67dbiiaavebmc4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3pPc5WhjtK from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_m46j33zrftqosaffxb2yoxedna/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_x5KXw8of6z from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1__x2v62plvcmazyh2cpwh2b4kqci/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_9PGUnRTUEq from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1__x2v62plvcmazyh2cpwh2b4kqci/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_BDBPQ2OIdg from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass-em_b6vqzs2fy3eggg6roxa2aoxbna/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_BZzgxxzB4n from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass-em_b6vqzs2fy3eggg6roxa2aoxbna/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import switch_locale_path_ssr_Q5FEyoGFul from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_2QpbkkvnqS from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import gsap_qE0fizvEy0 from "/app/plugins/gsap.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import sonner_client_l6WBUspFNo from "/app/plugins/sonner.client.ts";
export default [
  revive_payload_client_EQ2LFEUD3g,
  unhead_iRk5vQtCiX,
  router_TrAQS82Pvl,
  _0_siteConfig_KzLAy9rLRM,
  directus_fdYmlw0WUJ,
  payload_client_3moUN1dtXk,
  navigation_repaint_client_2wNRuzFp8g,
  check_outdated_build_client_YlxbURKpEw,
  chunk_reload_client_ViK40YZjpZ,
  plugin_vue3_VQIxS4wqOh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lc6gMMdujb,
  plugin_3pPc5WhjtK,
  titles_x5KXw8of6z,
  defaultsWaitI18n_9PGUnRTUEq,
  siteConfig_BDBPQ2OIdg,
  inferSeoMetaPlugin_BZzgxxzB4n,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_Q5FEyoGFul,
  i18n_2QpbkkvnqS,
  plugin_6Ph1iQzNHk,
  gsap_qE0fizvEy0,
  sentry_client_shVUlIjFLk,
  sonner_client_l6WBUspFNo
]