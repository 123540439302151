interface DirectusTranslationStructure {
  languages_code: string
  [key: string]: any
}

/**
 * Returns the directus translation object for the current locale.
 * @param translations A collection of directus translations, as they're returned by the directus SDK.
 */
export function dt<T extends DirectusTranslationStructure>(translations: T[] | null | undefined) {
  const { $i18n } = useNuxtApp()
  // console.log('LANGUAGE', $i18n.locale.value)
  if (!translations)
    return null
  return translations.find((translation) => {
    // console.log(translation.languages_code, $i18n.locale.value)
    return translation.languages_code === $i18n.locale.value
  })
}
