import { aggregate, createDirectus, readItem, readItems, readSingleton, rest } from '@directus/sdk'

export default defineNuxtPlugin({
  name: 'directus',
  enforce: 'pre',
  async setup(_nuxtApp) {
    const { public: config } = useRuntimeConfig()
    const directus = createDirectus(config.directus.url).with(rest())
    return {
      provide: { directus, readItem, readItems, readSingleton, aggregate },
    }
  },

})
