export interface PageNotFound {
  id: number
  translations: DirectusTranslation<{
    title: string
    description: string
    home_cta: string
  }>
}

export function usePageNotFound() {
  const { $directus, $readSingleton } = useNuxtApp()
  return useAsyncData<PageNotFound>('page_not_found', async () => {
    return await $directus.request($readSingleton('page_not_found', {
      fields: ['*', {
        translations: ['*'],
      }],
    })) as PageNotFound
  })
}
