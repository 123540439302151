<script setup lang="ts">
const props = defineProps<{
  link: string
  text: string
}>()
</script>

<template>
  <NuxtLinkLocale
    :to="props.link"
    class="inline-block border border-neutral-white bg-neutral-white px-2.5rem py-1rem text-center text-1.3rem text-neutral-black tracking-4px uppercase transition duration-300 hover:bg-transparent hover:text-neutral-white"
  >
    {{ props.text }}
  </NuxtLinkLocale>
</template>

<style scoped>

</style>
