export function useDirectusSEO(seo: MaybeRefOrGetter<{
  translations: DirectusTranslation<{
    title: string
    description: string
    og_image: string
  }>
  no_follow: boolean
  no_index: boolean
}>) {
  const SeoData = toRef(seo)

  const title = computed(() => dt(SeoData.value.translations)?.title)
  const description = computed(() => dt(SeoData.value.translations)?.description)

  const route = useRoute()

  useSeoMeta({
    title: title.value,
    description: description.value,
    ogTitle: title.value,
    ogDescription: description.value,
    ogUrl: route.fullPath,
    twitterCard: 'summary_large_image',
  })

  const robotsHeaderContent: string[] = []
  if (SeoData.value.no_index)
    robotsHeaderContent.push('no_index')
  if (SeoData.value.no_follow)
    robotsHeaderContent.push('no_follow')

  if (robotsHeaderContent.length <= 0) {
    useHead({
      meta: [
        {
          name: 'robots',
          content: robotsHeaderContent.join(','),
        },
      ],
    })
  }
}
